<template>
  <div class="fineArts">
    <div v-for="(item, j) in categoryNews" :key="j">
      <div v-if="item.NewsInfos.length > 0">
        <div class="title">
          <span class="name">{{ item.CategoryName }}</span>
          <router-link
            class="li"
            :to="'/H5/fineArts/newsList?categoryId=' + item.Id"
          >
            <span class="more"
              >查看更多<van-icon name="arrow" size="14"
            /></span>
          </router-link>
        </div>
        <ul class="study">
          <li
            v-for="(newItem, i) in item.NewsInfos"
            :key="i"
            class="li"
            @click="handleTo(newItem)"
          >
            <img :src="newItem.CoverImg" alt="" />
            <div class="details">
              <p class="decimalPoint-2">{{ newItem.Title }}</p>
              <p>{{ newItem.CreateTime | dataYMD }}</p>
            </div>
          </li>
        </ul>
        <div class="he_10"></div>
      </div>
    </div>

    <van-empty v-if="categoryNews.length == 0" description="暂无资讯" />
  </div>
</template>
<script>
import noData from "@/components/noData";
import { queryNewsCategoryAndNews, updateNewsReadCount } from "@/api/fineArts";
export default {
  name: "index",
  components: { noData },
  computed: {},
  watch: {},
  data () {
    return {
      route: {},
      categoryNews: [],
    };
  },
  mounted () {
    this.route = this.$route.query;
    this.init();
  },
  methods: {
    handleTo (obj) {
      if (obj.JumpUrl) {
        window.open(obj.JumpUrl);
        this.postUpdateNewsReadCount(obj.Id)
      } else {
        let path = "/H5/informationListDetails";
        let query = {
          id: obj.Id,
        };
        this.$router.push({ path, query: query });
      }
    },
    //浏览量自动+1
    async postUpdateNewsReadCount (id) {
      let parm = "?Id=" + id
      const res = await updateNewsReadCount(parm);
    },
    async init () {
      let parm = "?parentId=" + this.route.type + "&top=3";
      const res = await queryNewsCategoryAndNews(parm);
      this.categoryNews = res.response;
    },
  },
};
</script>
<style lang="less" scoped>
.fineArts {
  padding: 15px;
  min-height: 100vh;
  background-color: #fff;

  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    line-height: 1;
    font-size: 12px;
    margin: 15px 0;
    .name {
      font-size: 16px;
      font-weight: 800;
    }
    .more {
      color: #333333;
      font-size: 12px;
      font-weight: 400;
      .van-icon {
        margin-left: 5px;
        vertical-align: bottom;
      }
    }
  }

  .study {
    li {
      display: flex;
      margin: 15px 0 0;
      padding-bottom: 15px;
      border-bottom: 1px solid #e6e6e6;
      &:last-child {
        border-bottom: none;
      }
      img {
        flex: 0 0 146px;
        width: 146px;
        height: 84px;
        border-radius: 6px;
        background: #e4e4e4;
        margin-right: 15px;
      }
      .details {
        display: flex;
        flex-wrap: wrap;
        align-content: space-between;
      }
      p {
        flex: 0 0 100%;
        color: #333;
        line-height: 1.5;
        &:nth-child(1) {
          font-weight: bold;
          font-size: 15px;
        }
        &:nth-child(2) {
          color: #999999;
          font-size: 13px;
        }
      }
    }
  }
}

/deep/video::-webkit-media-controls-fullscreen-button {
  display: none;
}
</style>
